<template>
  <div class="create-coupon-step2">
    <b-row>
      <b-col class="col-6 col-md-4 col-lg-3 mb-4">
        <div class="heading">COUPON SERIES</div>
        <div class="details" v-if="payload.numerical_component !== '0'">
          {{ payload.series_name + numerical_component_value (payload.numerical_component) }}
        </div>
        <div class="details" v-else>
          {{ payload.series_name }}
        </div>
      </b-col>
      <b-col class="col-6 col-md-4 col-lg-3 mb-4">
        <div class="heading">DATE</div>
        <div class="details">
          {{ payload.from_date | moment("DD/MM/YYYY") }} to
          <span v-if="payload.to_date">{{
            payload.to_date | moment("DD/MM/YYYY")
          }}</span>
          <span v-else> onwards </span>
        </div>
      </b-col>
      <b-col class="col-6 col-md-4 col-lg-3 mb-4">
        <div class="heading">TYPE OF COUPON</div>
        <div class="details">
          {{ payload.type }}
        </div>
      </b-col>
      <b-col class="col-6 col-md-4 col-lg-3 mb-4">
        <div class="heading">Coupon Details</div>
        <div class="details" v-if="payload.type === 'BOGO'">
          Buy {{ payload.offer_buy }} Get {{ payload.offer_get }}
        </div>
        <div class="details" v-if="payload.type === 'Percent Off'">
          {{ payload.discount_percentage }} percent off
        </div>
        <div class="details" v-if="payload.type === 'Gift with Purchase'">
          {{ payload.add_gift }}
        </div>
        <!-- <div class="details">
          {{ payload.discount_percentage }}
          {{ payload.discount_percentage ? "%" : "" }}
        </div> -->
      </b-col>
      <b-col class="col-6 col-md-4 col-lg-3 mb-4">
        <div class="heading">Region(s)</div>
        <div class="details">
          {{ payload.regions.join() }}
        </div>
      </b-col>
      <b-col class="col-6 col-md-4 col-lg-3 mb-4">
        <div class="heading">CHANNELS</div>
        <div class="details">
          {{ payload.channels.join() }}
        </div>
      </b-col>
      <b-col class="col-lg-6 col-12  mb-4">
        <div class="heading">Limitations</div>
        <div class="details">
          <a
            href="javascript:void(0)"
            @mouseover="preview = true"
            @mouseleave="preview = false"
            >Preview</a
          >
        </div>
        <div class="ml-4 privew-popup" v-show="preview">
          {{ payload.limitations }}
          <svg
            class="ml-4"
            id="Component_3_37"
            data-name="Component 3 – 37"
            xmlns="http://www.w3.org/2000/svg"
            width="20.333"
            height="20.333"
            viewBox="0 0 20.333 20.333"
          >
            <path
              id="Path_22490"
              data-name="Path 22490"
              d="M17.716,2.519a2.287,2.287,0,0,1,.032,3.2l-8.261,8.6a2.288,2.288,0,0,1-1.6.7l-3.47.073.073-3.493a2.287,2.287,0,0,1,.674-1.574l8.444-8.4a2.288,2.288,0,0,1,3.23,0Z"
              transform="translate(1.81 0.002)"
              fill="#1374d6"
            />
            <path
              id="Path_22491"
              data-name="Path 22491"
              d="M11.415.333a1.017,1.017,0,1,1,0,2.033H4.4A2.033,2.033,0,0,0,2.367,4.4V16.6A2.033,2.033,0,0,0,4.4,18.633H16.6A2.033,2.033,0,0,0,18.634,16.6V11.516a1.017,1.017,0,1,1,2.033,0V16.6A4.067,4.067,0,0,1,16.6,20.666H4.4A4.067,4.067,0,0,1,.334,16.6V4.4A4.067,4.067,0,0,1,4.4.333Z"
              transform="translate(-0.334 -0.333)"
              fill="#1374d6"
              opacity="0.3"
            />
          </svg>
        </div>
      </b-col>
    </b-row>
    <!-- ADD COUPON CODES -->
    <b-row>
      <b-col>
        <b-form-group
          id="fieldset"
          label="Coupon Codes"
          label-for="coupon-codes"
          class="mt-5"
        >
          <b-textarea
            rows="10"
            v-model="coupon_codes"
            placeholder="Please input one coupon code on each line."
          />
          <!-- <span class="errors" v-if="invalidCoupons"
            >Entered coupons aren't valid.</span
          > -->
        </b-form-group>
        <div v-if="type == 'edit'">
          <span v-if="isRejected" class="coupon-error-2"
            >Coupon codes: {{ rejectedCoupons.join(", ") }} are not valid.
            Please follow format specified in Coupon Series.</span
          >
          <br />
          <span v-if="isShow" class="coupon-error-2">{{ couponError }}</span>
          <br />
          <br />
          <span v-if="showDuplicateError" class="coupon-error-2"
            >Duplicate Coupon: {{ duplicateValue.join(", ") }}</span
          >
          <br />
          <b-button class="add-btn edit" @click="chooseFiles()"
            >UPLOAD COUPON BY CSV</b-button
          >
        </div>
        <div v-else>
          <b-button class="add-btn csv" @click="chooseFiles()"
            >UPLOAD COUPON BY CSV</b-button
          >
          <span v-if="isRejected" class="coupon-error"
            >Coupon codes: {{ rejectedCoupons.join(", ") }} are not valid.
            Please follow format specified in Coupon Series.</span
          >
          <br />
          <span v-if="isShow" class="coupon-error-1">{{ couponError }}</span>
          <br />
          <br />
          <span v-if="showDuplicateError" class="coupon-error-1"
            >Duplicate Coupon: {{ duplicateValue.join(", ") }}</span
          >
        </div>

        <input
          id="fileUpload"
          type="file"
          @change="onFileChange"
          hidden
          accept=".csv"
        />
      </b-col>
    </b-row>

    <!-- ADD BUTTON -->
    <b-row class="mt-8 d-flex justify-content-end">
      <div class="p-3">
        <b-button :disabled="isLoading" class="cancel-btn" @click="handleCancel"
          >Cancel
        </b-button>
      </div>
      <div class="p-3">
        <b-button
          :disabled="isLoading"
          class="add-btn submit"
          @click="handleSubmit"
          >{{ type == "edit" ? "Update Coupons" : "Submit" }}
          <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
      </div>
    </b-row>

    <!-- <b-modal
      id="limitation-preview"
      title="Limitations Preview"
      ok-only
      ok-title="Close"
      size="sm"
      centered
    >
      <span>{{ payload.limitations }}</span>
    </b-modal> -->
  </div>
</template>
<style lang="scss">
.privew-popup {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  background-color: #f3fcff;
  border-radius: 13px;
  box-shadow: 0 10px 20px #eee;
  padding: 8px 15px;
}
.create-coupon-step2 {
  font-family: poppins;
  .heading {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .details {
    font-size: 20px;
    font-weight: 500;
    color: #707070;
    a {
      text-decoration: underline;
    }
  }
  #fieldset {
    label {
      font-size: 22px !important;
      font-weight: bold !important;
    }
    textarea {
      font-size: 18px;
      padding: 12px 20px;
      border-radius: 11px;
    }
  }
  .add-btn {
    font-size: 16px;
    font-weight: bold;
    width: unset;
    height: unset;
    background: #1374d6 0% 0% no-repeat padding-box;
    border-radius: 10px;
    letter-spacing: unset;
    color: #ffffff;
    float: unset;
    transition: all 0.3s ease;
  }
  .add-btn.edit {
    width: 100%;
    text-align: center;
    background: transparent;
    border-radius: 10px;
    letter-spacing: unset;
    color: #1374d6;
    border: 1px #1374d6 dashed;
  }
  .add-btn.csv {
    padding: 8px 20px;
  }
  .add-btn.submit {
    padding: 8px 50px;
  }
  .cancel-btn {
    width: unset;
    height: unset;
    background: #bdd8f4;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 20px;
    font-weight: bold;
    // letter-spacing: 1.6px;
    color: #1374d6;
  }
}
.coupon-error-1 {
  color: red;
  font-size: 15px;
  font-weight: bold;
  margin-left: 285px;
}
.coupon-error-2 {
  color: red;
  font-size: 15px;
  font-weight: bold;
}
.coupon-error {
  color: red;
  font-size: 15px;
  font-weight: bold;
  margin-left: 42px;
}
.create-coupon-step2 {
  .payout-slab {
    box-shadow: 0px 12px 44px #7272721a;
    border-radius: 20px;
    padding: 30px;
    margin: 30px;
  }
  .add-btn {
    font-size: 16px;
    font-weight: bold;
    width: unset;
    height: unset;
    background: #1374d6 0% 0% no-repeat padding-box;
    border-radius: 10px;
    letter-spacing: unset;
    color: #ffffff;
    float: unset;
    transition: all 0.3s ease;
  }
  .add-btn.submit {
    padding: 8px 50px;
  }
  textarea.form-control {
    background: #f3fcff 0% 0% no-repeat padding-box;
  }
  .errors {
    color: red;
    padding: 5px;
    font-weight: 500;
  }
}
</style>

<script>
import ApiService from "../../../core/services/api.service";
import ToasterService from "../../../core/services/toaster.service";
import { bus } from "../../../main";

export default {
  name: "create-coupon-step2",
  props: ["payload", "type", "data"],
  components: {},
  watch: {
    coupon_codes: function(val) {
      console.log("coupon_codes", val);
      if (val === "" || val === "") {
        this.isRejected = false;
        this.isShow = false;
        this.showDuplicateError = false;
      }
    },
  },
  data: () => ({
    coupon_series_id: "",
    isRejected: false,
    rejectedCoupons: [],
    isShow: false,
    couponError: "",
    client_id: null,
    rules: [],
    isLoading: false,
    coupon_codes: "",
    preview: false,
    duplicateValue: [],
    showDuplicateError: false,
  }),
  mounted() {
    console.log();
    if (this.type == "edit") {
      this.client_id = this.payload.client_id;
      this.coupon_codes = this.payload.coupon_codes;
      let desiredData = replaceCommaLine(this.coupon_codes);
      function replaceCommaLine(data) {
        //convert string to array and remove whitespace
        let dataToArray = data.split(",").map((item) => item.trim());
        //convert array to string replacing comma with new line
        return dataToArray.join("\n");
      }
      this.coupon_codes = desiredData;
    } else {
      let desiredData = replaceCommaLine(this.coupon_codes);
      function replaceCommaLine(data) {
        //convert string to array and remove whitespace
        let dataToArray = data.split(",").map((item) => item.trim());
        //convert array to string replacing comma with new line
        return dataToArray.join("\n");
      }
      this.coupon_codes = desiredData;
      console.log(desiredData);
      this.client_id = this.$route.params.id;
      this.coupon_series_id = this.data.data.coupon_series_id;
      // this.coupon_codes = this.data.data.coupon_codes;
      // this.client_id = this.data.data.client_id;
    }

    if (this.type === "EditCoupon") {
      this.coupon_codes = this.data.data.coupon_codes;
      console.log("========", this.coupon_codes);
      let desiredData = replaceCommaLine(this.coupon_codes);
      function replaceCommaLine(data) {
        //convert string to array and remove whitespace
        let dataToArray = data.split(",").map((item) => item.trim());
        //convert array to string replacing comma with new line
        return dataToArray.join("\n");
      }

      this.coupon_codes = desiredData;
      this.client_id = this.data.data.client_id;
    }
  },
  created() {
    bus.$on("selectedCoupon", (data) => {
      // this.coupon_codes.replace('p', '')
      let coupons = this.coupon_codes.split("\n");
      let filteredCoupons = [];
      for (let i = 0; i < coupons.length; i++) {
        if (coupons[i] === data.coupon) {
        } else {
          filteredCoupons.push(coupons[i]);
        }
      }
      this.coupon_codes = filteredCoupons.join("\n");
    });
  },
  methods: {
    numerical_component_value(value) {
      return "x".repeat(value);
      let res = "";
      for (let i = 1; i <= value; i++) {
        res = res + i;
      }
      return res;
    },
    handleSubmit() {
      console.log(
        "payload.numerical_component",
        this.payload.numerical_component.length
      );
      this.isShow = false;
      this.showDuplicateError = false;
      this.isRejected = false;
      let coupon_val = this.coupon_codes.length
        ? this.coupon_codes.split("\n")
        : this.coupon_codes;
      var coupon_codes;
      if (coupon_val) {
        console.log("coupon_val", coupon_val);
        var filtered = coupon_val.filter(function(el) {
          return el != "";
        });
        var coupon_codes = filtered;
        console.log("coupon_codes", coupon_codes);
        // check for duplicate value
        if (coupon_codes) {
          let findDuplicates = (coupon_codes) =>
            coupon_codes.filter(
              (item, index) => coupon_codes.indexOf(item) != index
            );
          var duplicateString = findDuplicates(coupon_codes);
          this.duplicateValue = duplicateString;
          if (this.duplicateValue.length != 0) {
            this.showDuplicateError = true;
          }
          if (this.$route.name !== "assign-coupon") {
            ApiService.post("/coupons/coupon-exist", {
              coupon_code: coupon_codes,
            })
              .then((response) => {
                this.isLoading = false;
                console.log(response, "response");
              })
              .catch((err) => {
                if (
                  err.response.data.message[0] === "Coupon codes not exist."
                ) {
                  this.couponError = "";
                  // this.isShow = false
                } else {
                  this.couponError = err.response.data.message[0];
                  this.isShow = true;
                  this.isLoading = false;
                }
              });
          }
          // check existing coupon
        }
        var rejectedOnes = [];
        // console.log(coupon_codes, "coupon_codes");
        for (let i = 0; i < coupon_codes.length; i++) {
          // console.log(
          //   coupon_codes[i].substring(0, this.payload.series_name.length)
          // );
          const last = coupon_codes[i].substring(
            this.payload.series_name.length
          );
          console.log("last", last);
          var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          console.log("-=----");
          var series_name = this.payload.series_name.toLowerCase();
          var series_name2 = coupon_codes[i]
            .substring(0, this.payload.series_name.length)
            .toLowerCase();

          console.log("series_name2", series_name2);
          console.log("series_name1", series_name);
          if (
            series_name === series_name2 &&
            parseInt(this.payload.numerical_component) === 0 &&
            last === ""
          ) {
          } else if (
            series_name === series_name2 &&
            last.trim().length === parseInt(this.payload.numerical_component) &&
            !last.trim().match(format) &&
            last.trim().match(/^[0-9]+$/)
            // Number.isInteger(last)
          ) {
            // this.isRejected = false;
          } else {
            console.log("else");
            rejectedOnes.push(coupon_codes[i]);
            this.isRejected = true;
            console.log("Rejected one", coupon_codes[i]);
          }
          console.log("----", this.payload.series_name);
        }

        this.rejectedCoupons = rejectedOnes;

        console.log("REjected", this.rejectedCoupons.length);
        if (this.rejectedCoupons.length != 0) {
          console.log("[][][][][][]", this.rejectedCoupons);
          return;
        }
      }
      let payloadBody = {
        ...this.payload,
        coupon_codes: coupon_codes,
        influencer_id: "5fe96cea75d92e0f8a445a42",
        coupon_series_id: this.coupon_series_id,
      };
      this.isLoading = true;

      if (this.type == "edit") {
        ApiService.post("/coupons/coupon-series/upload-coupon", {
          // coupon_codes: coupon_codes,
          coupon_series_id: this.payload.coupon_series_id,
          coupons: coupon_codes,
        })
          .then((response) => {
            this.isLoading = false;
            this.handleCancel(response);
          })
          .catch((err) => {
            this.couponError = err.response.data.message[0];
            this.isShow = true;
            this.isLoading = false;
            // ToasterService.makeToast(
            //   this,
            //   "Error",
            //   err.response.data.message[0],
            //   "danger"
            // );
          });
      } else if (this.type === "EditCoupon") {
        ApiService.post("/coupons/coupon-series/update", payloadBody)
          .then((response) => {
            localStorage.removeItem("copyCoupon"), (this.isLoading = false);
            this.handleCancel(response);
          })
          .catch((err) => {
            this.isLoading = false;
            this.couponError = err.response.data.message[0];
            this.isShow = true;
            // ToasterService.makeToast(
            //   this,
            //   "Error",
            //   err.response.data.message[0],
            //   "danger"
            // );
          });
      } else {
        ApiService.post("/coupons/coupon-series/create", payloadBody)
          .then((response) => {
            console.log(response.data.message[0]);
            if (response.data.message[0] === "Coupon Series already exist.") {
              localStorage.removeItem("copyCoupon"), (this.isLoading = false);
              ToasterService.makeToast(
                this,
                `${
                  response.data.message[0] == "Coupon Series already exist."
                    ? "Error"
                    : "Success"
                }`,
                response.data.message[0],
                `${
                  response.data.message[0] == "Coupon Series already exist."
                    ? "danger"
                    : "info"
                }`
              );
              return;
            }
            localStorage.removeItem("copyCoupon"), (this.isLoading = false);
            this.handleCancel(response);
          })
          .catch((err) => {
            this.isLoading = false;
            this.couponError = err.response.data.message[0];
            this.isShow = true;
            // ToasterService.makeToast(
            //   this,
            //   "Error",
            //   err.response.data.message[0],
            //   "danger"
            // );
          });
      }
    },
    handleCancel(response) {
      this.$router
        .push({
          name: "coupon-management",
          params: { id: this.client_id },
        })
        .then(() => {
          if (!response) return;
          ToasterService.makeToast(
            this,
            `${
              response.data.message[0] == "Coupon Series already exist."
                ? "Error"
                : "Success"
            }`,
            response.data.message[0],
            `${
              response.data.message[0] == "Coupon Series already exist."
                ? "danger"
                : "info"
            }`
          );
        });
    },

    formatDate(dateString) {
      if (!dateString) {
        return;
      }
      let options = { day: "numeric", month: "long", year: "numeric" };
      let date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    },

    chooseFiles: function() {
      document.getElementById("fileUpload").click();
    },

    onFileChange(e) {
      var files = e.target.files,
        f = files[0];
      this.getText(f);
    },

    getText(fileToRead) {
      var reader = new FileReader();
      reader.readAsText(fileToRead);
      reader.onload = this.loadHandler;
    },

    loadHandler(event) {
      var csv = event.target.result;
      this.process(csv);
    },
    process(csv) {
      this.coupon_codes = "";
      console.log("csv", csv);
      // Newline split
      let lines = csv.split("\n");
      this.coupon_codes = lines;
      this.coupon_codes = lines.map((x) => x).join("\n");
      // console.log('lines', lines)
      // let result = [];
      // const headers = lines[0].split("\n");
      // console.log('header', headers)
      // for (let i = 1; i < lines.length - 1; i++) {
      //   let obj = {};

      //   //Comma split
      //   let currentline = lines[i].split("\n");

      //   for (let j = 0; j < headers.length; j++) {
      //     obj[headers[j]] = currentline[j];
      //   }
      //   result.push(obj);
      //   this.coupon_codes = result.map((x) => x.coupon).join("\n");
      // }
    },
  },
  computed: {
    // invalidCoupons() {
    //   if (this.coupon_codes) {
    //     let arr = this.coupon_codes.replace(/\s/g, "").split(",");
    //     let found = arr.find(
    //       (x) =>
    //         x.indexOf(" ") >= 0 ||
    //         x.length >
    //           this.payload.series_name.length +
    //             (this.payload.numerical_component
    //               ? this.payload.numerical_component.length
    //               : 0)
    //     );
    //     if (found) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },
  },
};
</script>
