<template>
  <div class="create-coupon-step1" id="my-create-coupon-step1">
    <form ref="form" class="form-data">
      <!-- ADD PAYOUT NAME -->
      <b-form-group>
        <b-row class="border-bottom mb-5 headings">
          <b-col class="col-3">
            <b-form-group
              id="name-fieldset"
              class="input-label"
              label="Name the Coupon Series"
              label-for="name"
            >
              <b-input
                id="name"
                class="input-field custom-input"
                :class="name && !nameValidate ? 'danger' : ''"
                col="3"
                v-model="name"
                type="text"
                required
                placeholder="Alphabetical Component"
                @keyup="nameValidation"
              >
              </b-input>

              <span style="color:red" v-if="showNameError">
                Invalid coupon series</span
              >
              <br />
              <p v-if="showError" style="color:red">
                Coupon Name already exist.
              </p>
            </b-form-group>
          </b-col>

          <!-- ALPHABETICAL COMPONENT -->
          <b-col class="col-3">
            <b-form-group
              id="numerical_component-fieldset"
              class="input-label"
              label="Numerical component"
              label-for="numerical_component"
            >
              <b-form-input
                id="numerical_component"
                class="input-field custom-input"
                col="2"
                v-model="numerical_component"
                type="number"
                min="0"
                max="9"
                :formatter="formatNumericalComponent"
                required
                placeholder="Numeric Component"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <!-- DEFAULT PAYOUT TYPE -->
          <b-col class="col-3 name-preview-parent">
            <span style="color: #939393;">Preview</span>
            <div class="name-preview">
              <h3>
                {{ name }}{{ numerical_component_value(numerical_component) }}
              </h3>
            </div>
          </b-col>

          <!-- DEFAULT PAYOUT -->
        </b-row>
      </b-form-group>

      <!-- ADD DATE -->
      <b-form-group id="fieldset" label="Add Date" label-for="add-date">
        <b-row id="add-date" class="border-bottom mb-5">
          <b-col class="pb-0 pt-0 col-3">
            <b-form-group
              id="fieldset-start-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="from"
              label-for="start-date"
            >
              <b-form-datepicker
                id="start-date"
                v-model="startDate"
                no-flip
                :min="new Date().toISOString().substr(0, 10)"
                :max="endDate"
                @context="onSelectStartDate"
                placeholder="1/10/2020"
                locale="en"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>

          <b-col class="pb-0 pt-0 col-3">
            <b-form-group
              id="fieldset-end-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="to"
              label-for="end-date"
              v-if="is_end_date"
            >
              <b-form-datepicker
                id="end-date"
                v-model="endDate"
                no-flip
                @context="onSelectEndDate"
                placeholder="31/10/2020"
                locale="en"
                :min="startDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
            <label class="" v-else>and onwards</label>
          </b-col>

          <b-col class="pb-0 pt-0 col-3">
            <span class="switch switch-sm switch-primary p-3">
              <label style="line-height: 26px">
                Switch to {{ is_end_date ? "open" : "close" }} date
                <input type="checkbox" name="" v-model="is_end_date" />
                <span></span>
              </label>
            </span>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD COUPON TYPE -->
      <b-form-group>
        <b-form-group
          id="coupon-type-fieldset"
          class="input-label"
          label="Type of Coupon"
          label-for="type-of-coupon"
        >
          <b-row id="type-of-coupon" class="border-bottom">
            <b-col class="col-8">
              <ul class="type-list pl-3">
                <li
                  :class="{ active: fType == 0 }"
                  @click="activeType(0)"
                  style="background-color: #00fdb1"
                >
                  {{ coupon_types[0] }}
                </li>
                <li
                  :class="{ active: fType == 1 }"
                  @click="activeType(1)"
                  style="background-color: #7600fd"
                >
                  {{ coupon_types[1] }}
                </li>
                <li
                  :class="{ active: fType == 2 }"
                  @click="activeType(2)"
                  style="background-color: #fbcd39"
                >
                  {{ coupon_types[2] }}
                </li>
              </ul>

              <!-- discount type -->
              <b-form-group
                v-if="fType == 0"
                id="discount-fieldset"
                class="input-label col-6"
                label="Percentage of Discount"
                label-for="discount"
              >
                <b-input-group>
                  <template #prepend>
                    <div class="range-wrapper-left">
                      <v-row
                        align-v="center"
                        class="
                          d-flex
                          justify-content-center
                          align-content-center
                          px-5
                        "
                      >
                        <b-col> %</b-col></v-row
                      >
                    </div>
                  </template>
                  <b-form-input
                    id="discount"
                    v-model="discount_percentage"
                    class="input-field custom-input"
                    type="number"
                    required
                    placeholder="add discount here"
                    max="3"
                  />
                </b-input-group>
                <span style="color:red" v-if="showPercentageError">
                  Max percentage discount is 100</span
                >
              </b-form-group>

              <!-- bogo type -->
              <b-form-group
                v-if="fType == 1"
                id="fieldset"
                label="Add Offer"
                label-for="add-offer"
                class="col-6"
              >
                <b-row id="add-offer">
                  <b-col align-self="center" class="col-auto">
                    <label>Buy</label>
                  </b-col>
                  <b-col class="col-auto">
                    <b-input-group
                      ><template #prepend> </template>
                      <b-dropdown
                        class="sar-btn"
                        :text="offer_buy ? offer_buy : 'Select'"
                      >
                        <b-dropdown-item
                          v-for="number in numbers"
                          :key="number"
                          @click="offer_buy = number"
                          >{{ number }}</b-dropdown-item
                        >
                      </b-dropdown>
                    </b-input-group>

                    <!-- <b-dropdown
          id="dropdown-left"
          text="buy-one"
          variant="primary"
          
          style="background-color:#FFFFFF"
        >
          <b-dropdown-item >1</b-dropdown-item>
          <b-dropdown-item >2</b-dropdown-item>
          <b-dropdown-item >3</b-dropdown-item>
          <b-dropdown-item >4</b-dropdown-item>
          <b-dropdown-item >5</b-dropdown-item>

        </b-dropdown> -->
                  </b-col>

                  <b-col align-self="center" class="col-auto">
                    <label>Get </label>
                  </b-col>
                  <b-col class="col-auto">
                    <b-input-group
                      ><template #prepend> </template>
                      <b-dropdown
                        class="sar-btn"
                        :text="offer_get ? offer_get : 'Select'"
                      >
                        <b-dropdown-item
                          v-for="number in numbers"
                          :key="number"
                          @click="offer_get = number"
                          >{{ number }}</b-dropdown-item
                        >
                      </b-dropdown>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>

              <!-- gift type -->
              <b-form-group
                v-if="fType == 2"
                id="fieldset"
                label="Add Gift"
                label-for="add-gift"
                class="col-6"
              >
                <b-input type="text" v-model="add_gift" />
              </b-form-group>

              <b-form-group
                id="fieldset"
                label="Limitations"
                label-for="limitations"
                class="col-6"
              >
                <b-textarea rows="8" v-model="limitations" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form-group>

      <!-- ADD SPECIFIC REGION -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_region"
                >
                  ADD SPECIFIC REGIONS
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_region"
                v-model="selectedRegions"
                attach="attach"
                :items="regions"
                outlined
                chips
                multiple
                placeholder="Search regions.."
                item-text="name"
                item-value="name"
                no-data-text="No regions available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD SPECIFIC CHANNELS -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_channel"
                >
                  ADD SPECIFIC CHANNELS
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_channel"
                v-model="selectedChannels"
                attach="attach"
                :items="channels"
                outlined
                chips
                multiple
                placeholder="Search channels.."
                item-text="name"
                item-value="name"
                no-data-text="No Channel available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD CUSTOMER TYPE -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_customer_type"
                >
                  ADD CUSTOMER TYPE
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_customer_type"
                v-model="selectedCustomerTypes"
                attach="attach"
                :items="customers"
                outlined
                chips
                multiple
                placeholder="Search customer type.."
                no-data-text="No customer type available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD BUTTON -->
      <b-form-group class="mt-15">
        <b-button
          v-if="type === 'EditCoupon'"
          :disabled="!isFormValidated || isLoading"
          class="add-btn"
          @click="handleSubmit"
          >Update <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
        <b-button
          v-else
          :disabled="!isFormValidated || isLoading"
          class="add-btn"
          @click="handleSubmit"
          >Add <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
      </b-form-group>
    </form>
  </div>
</template>
<style lang="scss">
#my-create-coupon-step1 {
  font-family: poppins !important;
}
.create-coupon-step1 {
  .form-data {
    .v-autocomplete {
      .v-input__slot {
        background: rgb(243 252 255);
        border: none;
        border-radius: 5px;
      }
    }
    .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
      max-height: 25px !important;
    }
    .type-list {
      list-style: none;
      display: flex;

      label {
        display: inline-block;
        margin-bottom: 0rem !important;
        font-size: 16px;
        // font-weight: 500 !important;
      }

      li {
        margin-right: 1rem;
        border: 1px solid #a4c5e2;
        padding: 5px 5px;
        color: black !important;
        cursor: pointer;
        font-size: 16px;
        border-radius: 5px;
        background-color: #cbe3f9 !important;
      }

      .active {
        color: white !important;
        font-weight: bold !important;
        background-color: #1374d6 !important;
      }
    }
    .range-wrapper-left {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px 0px 0px 8px;
      font-size: 18px;
      font-weight: bold;
      margin-left: 1px;
      border: 1px solid #e4e6ef;
      height: 50px;
      button,
      button:hover,
      button:active,
      button:focus {
        background: f3fcff !important;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
      }
    }
    margin-top: 30px;
    textarea.form-control {
      background: #f3fcff 0% 0% no-repeat padding-box;
    }
    .b-form-datepicker {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      height: 50px;
      font-size: 18px;
    }
    input {
      height: 50px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      font-size: 18px;
      color: #000000;
      border-radius: 5px;
    }
    .danger {
      border-color: red;
    }
    label {
      align-self: center;
      font-size: 15px;
      color: #000000;
    }
    .sub-label {
      font-size: 14px !important;
      font-weight: bold !important;
    }
    #checkbox-1::after {
      width: 35px;
      height: 32px;
      background: #00a4f3 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
    }
    .sar-btn {
      button {
        background: #f3fcff 0% 0% no-repeat padding-box;
        font-size: 12px;
        color: #000000;
      }
    }

    .checkbox-xl .custom-control-label::before,
    .checkbox-xl .custom-control-label::after {
      width: 35px;
      height: 32px;
      border-radius: 8px;
      opacity: 1;
    }

    .checkbox-xl .custom-control-label {
      padding-left: 35px;
      padding-top: 7px;
    }
    .campaign-label {
      text-align: left;
      font: normal normal bold 18px/27px Poppins;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    .add-btn {
      float: right;
      width: 229px;
      height: 53px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1.6px;
      color: #ffffff;
    }
    .currency-dropdown {
      width: 100%;
      height: 50px;
      button,
      button:focus {
        text-align: left;
        background: #f3fcff;
        color: #000000;
        font-size: 18px;
        font-weight: 500;
      }
      button::after {
        font-size: 14px;
        color: #000000;
        line-height: 30px;
        float: right;
      }
      ul {
        width: 100%;
      }
    }
    .v-chip--select {
      background: #e8f1fb !important;
      color: #000 !important;
      font-weight: bold !important;
    }
    .v-input__control {
      fieldset {
        border: 1px solid #d4d4d4;
        border-radius: 9px;
      }
    }
    .plus-icon {
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 137px;
      margin: 0px 0px 2px 12px;
      cursor: pointer;
    }
    .border-bottom {
      border-bottom: 1px solid #707070;
    }
    .name-preview-parent {
      // padding: 30px;
      border-left: 1px solid #7070704d;
      margin: 10px;
    }
    .name-preview {
    }
  }
  .form-group {
    margin-bottom: 0px;
  }
}
.headings {
  // font-weight:1rem;
}
</style>

<script>
import ApiService from "../../../core/services/api.service";
import { bus } from '../../../main'
export default {
  name: "create-coupon-step1",
  props: ["payload", "type"],
  components: {},
  watch: {
    name: function(value) {
      if (value.length > 10) {
        this.showNameError = true;
      } else {
        this.showNameError = false;
      }
    },
    discount_percentage: function(value) {
      if (parseInt(value) > 100) {
        this.showPercentageError = true;
      } else {
        this.showPercentageError = false;
      }
    },
  },
  data: () => ({
    showNameError: false,
    showPercentageError: false,
    series_name: "",
    editPayload: {},
    client_id: null,
    startDate: "",
    startCompleteDate: null,
    endCompleteDate: null,
    endDate: "",
    name: "",

    is_region: false,
    is_channel: false,
    is_customer_type: false,
    isLoading: false,
    nameValidate: false,
    is_end_date: false,
    selectedRegions: [],
    selectedCustomerTypes: [],
    regions: [
      { name: "UAE" },
      { name: "SAU" },
      { name: "BHR" },
      { name: "KWT" },
    ],
    selectedChannels: [],
    channels: [{ name: "AFFILIATES" }, { name: "INFLUENCERS" }],

    customers: ["Returning", "New"],
    couponSeries: [],
    fType: 0,
    offer_buy: null,
    offer_get: null,
    add_gift: "",

    numerical_component: null,
    discount_percentage: null,
    coupon_types: ["Percent Off", "BOGO", "Gift with Purchase"],
    limitations: "",
    copyCoupon: "",
    numbers: ["1", "2", "3", "4", "5"],
    copy_coupon_page: false,
    showError: false,
  }),
  mounted() {
    if ("copy_page" in this.$route.query) {
      this.copy_coupon_page = this.$route.query.copy_page;
    }
    this.coupon_series_id = this.$route.params.id;
    this.getCouponDetails();
    this.copyCoupon = localStorage.getItem("copyCoupon");
    if (this.copyCoupon) {
      this.getCouponSeries(this.copyCoupon);
    }
    this.client_id = this.$route.params.id;
    this.selectedCustomerTypes = this.customers;
    this.copy_page = this.$route.query.type;
  },
  created() {
    bus.$on("changeCouponSteps", (data) => {
      console.log(data);
      this.handleSubmit();
    });
  },
  methods: {
    getCouponDetails() {
      if (this.copy_coupon_page === false) {
        ApiService.get(`/coupons/coupon-series/${this.coupon_series_id}`)
          .then((response) => {
            console.log("Step1");
            console.log(response);
            this.editPayload = response.data.data;
            console.log("opp", this.editPayload);
            // set Data
            this.name = this.editPayload.series_name;
            this.nameValidation(this.name);
            this.numerical_component = this.editPayload.numerical_component;
            this.startDate = this.editPayload.from_date;
            this.endDate = this.editPayload.to_date;
            this.client_id = this.editPayload.client_id;
            this.selectedChannels = this.editPayload.channels;
            this.selectedRegions = this.editPayload.regions;
            this.selectedCustomerTypes = this.editPayload.customer_types;
            this.add_gift = this.editPayload.add_gift;
            this.offer_buy = this.editPayload.offer_buy;
            this.offer_get = this.editPayload.offer_get;
            this.limitations = this.editPayload.limitations;
            this.discount_percentage = this.editPayload.discount_percentage;
            this.coupon_types.map((item, index) => {
              if (item == this.editPayload.type) {
                console.log(this.editPayload.type);
                this.fType = index;
                console.log(this.type);

                return;
              }
            });
            this.is_channel = this.editPayload.is_channel;
            this.is_customer_type = this.editPayload.is_customer_type;
            this.is_region = this.editPayload.is_region;
            this.is_end_date = this.editPayload.is_end_date;

            this.payload = response.data.data;
            this.clientName = this.payload.client_name;
            this.payload.coupon_series_id = this.coupon_series_id;
            this.payload.coupon_codes = response.data.data.coupon_codes.join(
              ","
            );
            this.clientId = response.data.data.client_id;
            this.getCouponsBySeriesId();
          })
          .catch((error) => {
            console.log("response response response", error);
          });
      }
    },
    /* event triggered on selecting start date */
    onSelectStartDate(ctx) {
      console.log(ctx, "---");
      this.startCompleteDate = ctx.activeDate;
      this.startDate = ctx.activeYMD;
    },

    /* event triggered on selecting end date */
    onSelectEndDate(ctx) {
      this.endCompleteDate = ctx.activeDate;
      this.endDate = ctx.activeYMD;
    },

    getCouponSeries(copyCoupon) {
      console.log("API");
      ApiService.get(`/coupons/coupon-series/${copyCoupon}`)
        .then((response) => {
          if (response.data.data) {
            let value = response.data.data;
            this.name = value.series_name;
            this.series_name = value.series_name;
            this.nameValidation(this.name);
            this.numerical_component = value.numerical_component
            this.startDate = value.from_date;
            this.endDate = value.to_date;
            this.client_id = value.client_id;
            this.selectedChannels = value.channels;
            this.selectedRegions = value.regions;
            this.selectedCustomerTypes = value.customer_types;
            this.add_gift = value.add_gift;
            this.offer_buy = value.offer_buy;
            this.offer_get = value.offer_get;
            this.limitations = value.limitations;
            this.discount_percentage = value.discount_percentage;
            this.coupon_types.map((item, index) => {
              if (item == value.type) {
                console.log(value.type);
                this.fType = index;
                console.log(this.type);

                return;
              }
            });
            this.is_channel = value.is_channel;
            this.is_customer_type = value.is_customer_type;
            this.is_region = value.is_region;
            this.is_end_date = value.is_end_date;
            console.log(this.numerical_component);
          }
          // this.payload.coupon_series_id = this.coupon_series_id;
          // this.payload.coupon_codes = response.data.data.coupon_codes.join(",");
          // this.clientId = response.data.data.client_id;
        })
        .catch((error) => {
          console.log("response response response", error);
        });
    },

    handleSubmit() {
      console.log("series_name", this.series_name);
      if (this.series_name === this.name && this.copy_coupon_page === true) {
        this.showError = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      
      const payload = {
        client_id: this.client_id,
        influencer_id: "",
        series_name: this.name,
        channels: this.selectedChannels,
        regions: this.selectedRegions,
        from_date: this.startDate,
        to_date:
          this.endDate && this.is_end_date === true
            ? this.endDate
            : "",
        customer_types: this.selectedCustomerTypes,
        type: this.coupon_types[this.fType],
        offer_buy: this.offer_buy,
        offer_get: this.offer_get,
        add_gift: this.add_gift,
        limitations: this.limitations,
        discount_percentage: this.discount_percentage,
        numerical_component: this.numerical_component,
        is_channel: this.is_channel,
        is_customer_type: this.is_customer_type,
        is_region: this.is_region,
        is_end_date: this.is_end_date,
      };
      console.log("payload", payload)
      this.$emit("payout-payload", payload);
    },
    nameValidation(e) {
      this.showError = false;
      let pattern = /[^a-zA-Z0-9 \\,\\.\\;]/g;
      let value = e.target ? e.target.value : e;
      if (value !== "" && pattern.test(value)) {
        this.nameValidate = false;
        return;
      }
      this.nameValidate = true;
    },

    activeType(value) {
      this.fType = value;
    },
    numerical_component_value(value) {
      return "x".repeat(value);
      let res = "";
      for (let i = 1; i <= value; i++) {
        res = res + i;
      }
      return res;
    },
    formatNumericalComponent(e) {
      return String(e).substring(0, 1);
    },
  },
  computed: {
    getDateStr() {
      let s = new Date().toLocaleDateString();
      return s;
    },
    isFormValidated() {
      if (
        this.name &&
        this.numerical_component &&
        this.nameValidate &&
        this.showPercentageError === false &&
        this.showNameError === false
      ) {
        this.$emit("formActive", true);

        return true;
      }
      this.$emit("formActive", false);
      return false;
    },
  },
};
</script>
