<template>
  <div>
    <div class="row ">
      <div class="col-xl-8 pl-0">
        <!-- <img src="media/images/adidas.svg" /> -->
        <!-- <img class="logo" :src="client_detail.logo" /> -->
        <h1 class="ml-4 main-header">
          <b>Edit Coupon</b>
        </h1>
        <h2 class="ml-4 client-name">for {{ clientName }}</h2>
      </div>

      <!-- steps stepper -->
      <div class="col-xl-4">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <template v-for="n in steps">
              <v-stepper-step
                color="#00a4f3"
                :key="`${n}-step`"
                :complete="e1 > n"
                :step="n"
                editable
              >
                {{ n === 1 ? "DEFINE RULES" : "SPECIFY MODELS" }}
              </v-stepper-step>

              <v-divider v-if="n !== steps" :key="n"></v-divider>
            </template>
          </v-stepper-header>
        </v-stepper>
      </div>
    </div>

    <!-- step 1 and step 2  -->
    <b-row>
      <b-col id="create-coupon">
        <v-stepper v-model="e1">
          <v-stepper-items>
            <v-stepper-content :key="`1-content`" step="1">
              <step1
                @payout-payload="payoutPayload"
                :payload="editPayload"
                :type="'EditCoupon'"
              />
            </v-stepper-content>

            <v-stepper-content :key="`2-content`" step="2">
              <step2 v-if="e1 > 1" :payload="payload" :type="'EditCoupon'" :data="editPayload"></step2>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import step1 from "./step1";
import step2 from "./step2";
import ApiService from "../../../core/services/api.service";
export default {
  components: {
    step1,
    step2,
  },
  computed: {
    ...mapGetters(["ClientGetters"]),
    client_detail() {
      return this.$store.getters.ClientGetters;
    },
  },
  data() {
    return {
      e1: 1,
      steps: 2,
      editPayload: {},
      clientName: "",
      payload: {
        payout_name: "",
        default_payout_type: "",
        channels: [],
        regions: [],
        from_date: null,
        to_date: null,
        default_currency: "",
        coupons: [],
        customers_type: [],
        payout_rules: [],
        is_order: false,
        is_slab: false,
        is_customer_type: false,
      },
    };
  },
  mounted() {
    this.clientName = this.$route.query.name;
    this.coupon_series_id = this.$route.params.id;
    this.getCouponSeries();
    // if user refresh this page directly so we've to hit api again to get client details.
    if (!this.client_detail || !this.client_detail.length) {
      this.$store.dispatch("CLIENT_ACTION", this.$route.params.id);
    }
  },
  methods: {
    getCouponsBySeriesId() {
      ApiService.post("coupons/coupon-series/coupons", {
        coupon_series_id: this.coupon_series_id,
      })
        .then((response) => {
          console.log("ppp", response);
          this.couponList = response.data.data.map((x) => {
            return {
              _id: x._id,
              coupon: x.code,
              channel: x.channel,
              orders: x.order_num,
              revenue: `${x.total_revenue_currency} ${x.revenue}`,
              date_added: new Date(x.active_from).toLocaleDateString(
                undefined,
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              ),
              status: x.status,
              action: "",
              series_name: x.series_title,
              assignment_history: x.assignment_history,
            };
          });
          this.filteredCoupons = this.couponList;

          console.log("response2 response2 response2", response);
        })
        .catch((error) => {
          console.log("response1 response1 response1", error);
        });
    },
    getCouponSeries() {
      ApiService.get(`/coupons/coupon-series/${this.coupon_series_id}`)
        .then((response) => {
          this.editPayload = response.data;
          this.payload = response.data.data;
          this.clientName = this.payload.client_name;
          this.payload.coupon_series_id = this.coupon_series_id;
          this.payload.coupon_codes = response.data.data.coupon_codes.join(",");
          this.clientId = response.data.data.client_id;
          this.getCouponsBySeriesId();
        })
        .catch((error) => {
          console.log("response response response", error);
        });
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    payoutPayload(payload) {
      this.payload = payload;
      this.nextStep(1);
    },
  },

  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
};
</script>

<style lang="scss">
#create-coupon {
  .v-stepper {
    border-radius: 20px;
    box-shadow: 0px 14px 44px #eee;
  }
}
.theme--light.v-stepper {
  background: transparent;
}
.logo {
  height: 70px;
}
.client-name {
  font: normal normal 600 15px/20px Poppins;
  letter-spacing: 0px;
  color: #939393;
}

input {
  height: 50;
  background: #f3fcff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  font-size: 18px;
  color: #000000;
}
::v-deep label {
  font-size: 1.2rem !important;
  // font-weight: 600 !important;
  color: #000 !important;
}
.checkbox-type {
  input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
    border-radius: 5px;
    border: none;
    background-color: #f3fcff;
  }
}
.checkbox-type {
  label {
    margin-right: 1rem;
    padding: 10px 9px;
    color: #000 !important;
    font-weight: 500;
    cursor: pointer;
    font-size: 18px;
    border-radius: 12px;
    background-color: #f3fcff !important;
    width: 70px;
    text-align: center;
  }
}
.checkbox-type input[type="checkbox"]:checked + label {
  background-color: #1374d6 !important;
  color: #ddf2f9 !important;
  font-weight: bold !important;
}
.add-btn {
  float: right;
  width: 229px;
  height: 53px;
  background: #1374d6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.6px;
  color: #ffffff;
}
.input-info {
  color: #000000;
  font-size: 14px;
  line-height: 30px;
}
.active-step,
.v-stepper__step__step primary {
  background: #00a4f3 0% 0% no-repeat padding-box !important;
}
.main-header {
  font-size: 2rem;
}
.v-stepper__content {
  border-radius: 20px;
}
</style>
